import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home/index.vue'),
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/Search/index.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/Product/index.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/views/Cart/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/index.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('@/views/Checkout/index.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('@/views/Pay/index.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('@/views/Pay/success.vue')
  },
  {
    path: '/failed',
    name: 'failed',
    component: () => import('@/views/Pay/failed.vue')
  },
  
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/Order/index.vue'),
  },
  // 主题页面
  {
    path: '/theme-0',
    name: 'theme-0',
    component: () => import('@/views/Theme/theme-0.vue')
  },
  {
    path: '/theme-1',
    name: 'theme-1',
    component: () => import('@/views/Theme/theme-1.vue')
  },
  {
    path: '/theme-2',
    name: 'theme-2',
    component: () => import('@/views/Theme/theme-2.vue')
  },
  {
    path: '/theme-3',
    name: 'theme-3',
    component: () => import('@/views/Theme/theme-3.vue')
  },
  {
    path: '/theme-4',
    name: 'theme-4',
    component: () => import('@/views/Theme/theme-4.vue')
  },
  {
    path: '/theme-5',
    name: 'theme-5',
    component: () => import('@/views/Theme/theme-5.vue')
  },
  // 辅助页面
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('@/views/Footer/about-us.vue')
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    component: () => import('@/views/Footer/terms-conditions.vue')
  },
  {
    path: '/return-refund-policy',
    name: 'return-refund-policy',
    component: () => import('@/views/Footer/return-refund-policy.vue')
  },
  {
    path: '/payment-methods',
    name: 'payment-methods',
    component: () => import('@/views/Footer/payment-methods.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/Footer/privacy-policy.vue')
  },
  {
    path: '/shipping-delivery',
    name: 'shipping-delivery',
    component: () => import('@/views/Footer/shipping-delivery.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('@/views/Footer/contact-us.vue')
  },
  // 404 页面
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('@/views/404.vue')
  },
  {
    // 路由重定向
    path: '/',
    redirect: {
      path: '/',
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // 每次路由跳转之后 新页面加载在x轴和y轴的初始位置
  scrollBehavior() {
    return {
      left: 0,
      top: 0,
    };
  },
})

// 全局前置守卫
router.beforeEach((to, from, next) => {

  if (to.path === '/checkout' && !store.state.userInfo.access_token) {
    next('/login');
  } else if (to.path === '/pay' && !store.state.userInfo.access_token) {
    next('/login');
  } else if (to.path === '/order' && !store.state.userInfo.access_token) {
    next('/login');
  } else {
    next(); // 正常跳转
  }

});

export default router
